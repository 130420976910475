<template>
  <div class="base">
    <div
      class="base_head scaleBanner"
      :style="'background-image: url(' + provinceInfo.companyImage + ');'"
    >
      <div class="base_head_name moveTopTitle">
        {{ provinceInfo.companyName }}
      </div>
    </div>
    <div class="base_introduce">
      <div class="center_title">
        <img
          src="../../static/newImg2/home.png"
          width="16px"
          @click="toPage('/')"
        />
        <span class="center_home"> > 浙江中峪道地药材有限公司</span>
      </div>
      <div class="base_introduce_title moveTopNormal">
        浙江中峪道地药材有限公司
      </div>
      <div class="base_introduce_content moveTopNormal">
        浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培<br />育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自<br />然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。
      </div>
      <div class="base_introduce_num moveTopNormal">
        <div
          class="base_introduce_num_ZZMJ"
          id="ZZMJ"
          @click="toPage('/medicinal')"
        >
          55,000
        </div>
        <div class="base_introduce_num_GZRY" id="GZRY">3,600</div>
        <div
          class="base_introduce_num_XSDW"
          id="XSDW"
          @click="toPage('/introduction', { id: 2 })"
        >
          5
        </div>
        <div
          class="base_introduce_num_CLSJ"
          id="QDSJ"
          @click="toPage('/medicinal')"
        >
          2023
        </div>
      </div>
      <div class="base_introduce_tips moveTopNormal">
        <div class="base_introduce_num_ZZMJ" @click="toPage('/medicinal')">
          种植面积(亩)
        </div>
        <div class="base_introduce_num_GZRY">工作人员(人)</div>
        <div
          class="base_introduce_num_XSDW"
          @click="toPage('/introduction', { id: 2 })"
        >
          下属单位(家)
        </div>
        <div class="base_introduce_num_CLSJ" @click="toPage('/medicinal')">
          种植密度(株/亩)
        </div>
      </div>
    </div>
    <div class="base_child_top moveTopNormal">
      <div class="base_child_top_title_cn">下属单位</div>
      <div class="base_child_top_title_en">SUBSIDIARY</div>
      <div class="base_child_top_img moveTopNormal">
        <el-carousel
          type="card"
          height="500px"
          indicator-position="none"
          ref="cityCarousel"
          @change="changeCityIndex"
          class="cityCarousel"
          :autoplay="false"
          arrow="always"
        >
          <el-carousel-item
            v-for="item in provinceInfo.city"
            :key="item.companyId"
          >
            <div
              class="base_child_top_img_out"
              @click="toCompanyDetail(item.companyId)"
            >
              <div class="base_child_top_img_out_img">
                <div>{{ item.companyName }}</div>
                <img
                  style="display: block"
                  :src="item.companyImage + imgSuffix"
                  width="100%"
                />
              </div>
              <div class="base_child_top_img_out_tips">
                <div class="base_child_top_img_out_tips_title">
                  {{ item.companyName }}
                </div>
                <div class="base_child_top_img_out_tips_desc">
                  {{ item.companyDesc }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="base_newsImg">
      <div class="base_newsImg_title">
        <div class="base_newsImg_title_bold">新闻中心</div>
        <div class="base_newsImg_title_normal">NEW CENTER</div>
      </div>
      <div class="base_news moveTopNormal">
        <div class="base_news_bg">
          <el-carousel
            height="500px"
            indicator-position="none"
            ref="newsImgCarousel"
            arrow="never"
            @change="changeNewsImgCarousel"
            class="newsImgCarousel"
          >
            <el-carousel-item
              v-for="(item, index) in newsList"
              :key="item.newsId + 'newsImg'"
            >
              <img
                src="../../static/newImg/img_loading.png"
                class="img_loading"
                v-show="imgSuffix"
              />
              <img
                :src="item.newsCover + imgSuffix"
                height="100%"
                @click="toDetail(item, 22)"
              />
            </el-carousel-item>
          </el-carousel>
          <div class="base_news_bg_bottom">
            <div
              class="base_news_bg_bottom_title"
              v-if="newsList.length"
              @click="toDetail(newsList[newsImageIndex], 22)"
            >
              {{ newsList[newsImageIndex].newsTitle }}
            </div>
            <div>
              <div
                :class="
                  newsImageIndex == index
                    ? 'base_news_bg_bottom_Index active'
                    : 'base_news_bg_bottom_Index'
                "
                v-for="(i, index) in newsList"
                @click="changeNewsImageIndex(index)"
                :key="index + 'newsImg1'"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="base_news_left">
          <div class="base_news_left_title">
            <div
              :class="
                newsIndex == 33
                  ? 'base_news_left_title_name active'
                  : 'base_news_left_title_name'
              "
              @mouseenter="
                changeNewsIndex33(33);
                newsIndex = 33;
              "
            >
              最新报道
            </div>
            <div
              :class="
                newsIndex == 34
                  ? 'base_news_left_title_name active'
                  : 'base_news_left_title_name'
              "
              @mouseenter="
                changeNewsIndex33(34);
                newsIndex = 34;
              "
            >
              新闻资讯
            </div>
          </div>
          <div
            class="base_news_left_list moveTop"
            @click="toDetail(item, 22)"
            v-for="item in newsList1"
            :key="'newsList' + item.newsId"
          >
            <div class="base_news_left_list_title">
              <div class="base_news_left_list_title_point"></div>
              {{ item.newsTitle }}
            </div>
            <div class="base_news_left_list_day">{{ item.publishTime }}</div>
          </div>
        </div>
      </div>
      <div class="base_news1 moveTopNormal">
        <div class="base_news1_bg">
          <img
            class="base_news1_bg_img"
            src="../../static/newImg/base_news1_title.png"
            height="14px"
          />
          <div class="base_news1_bg_title">行业资讯</div>
          <div
            class="base_news1_bg_list moveTop"
            v-for="item in newsList3"
            :key="item.id"
            @click="toDetail(item, 22)"
          >
            <div class="base_news1_bg_list_point"></div>
            {{ item.newsTitle }}
          </div>
        </div>
        <div class="base_news1_bg">
          <img
            class="base_news1_bg_img"
            src="../../static/newImg/base_news1_title.png"
            height="14px"
          />
          <div class="base_news1_bg_title">热点专题</div>
          <div
            class="base_news1_bg_list moveTop"
            v-for="item in newsList4"
            :key="item.id"
            @click="toDetail(item, 22)"
          >
            <div class="base_news1_bg_list_point"></div>
            {{ item.newsTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="base_banner moveTopNormal">
      <img
        src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/e4e8ea1d446c4ba1bcb5e76dbbd163ed.png"
        width="1600px"
      />
    </div>
    <!-- 单个项目 -->
    <!-- <div class="base_child_info" v-show="momentProjectList.length">
      <div class="base_child_info_title">
        <div class="base_child_info_title_cn">项目列表</div>
        <div class="base_child_info_title_en">PROJECT LIST</div>
      </div>
      <div class="base_child_info_project">
        <div class="base_child_info_project_item_only" v-for="item in momentProjectList" :key="item.projectId"
          @click="toProject(item)">
          <div class="base_child_info_project_item_img">
            <img src="../../static/newImg/img95.jpg"  />
          </div>
          <div class="base_child_info_project_item_tips">
            <div class="base_child_info_project_item_tips_left">项目名称：平顶山仿野生白术种植基地</div>
            <div class="base_child_info_project_item_tips_left">合同年限：2023年8月1日起 - 2043年7月31日</div>
            <div class="base_child_info_project_item_tips_left">所属单位：浙江中峪道地药材有限公司</div>
            <div class="base_child_info_project_item_tips_left">项目介绍：<br>
              <div class="base_child_info_project_item_tips_right">
                新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时交付市场。基地在浙江中峪控股集团的高度引领下,由农业版块专业项目负责人统筹科学管理;农科院、农技站等白术种植专家常年驻扎指导基地技术工作;并聘请当地有经验的老百姓进行种植养护工作。全方面保障中峪道地药材平顶山项目基地的先进样板落地闭环。浙江中峪道地药材有限公司已经被评为新昌沃洲重点农业企业,并被授予第一批“共富工坊”荣誉称号。
              </div>
            </div>
            <div class="base_child_info_project_item_tips_more">
              了解详情<img src="../../static/newImg/位图9.png" width="16px" height="16px" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 多个项目 -->
    <div
      class="base_child_info"
      v-show="provinceInfo.city && provinceInfo.city.length > 0"
    >
      <!-- <div class="base_child_info_title">
        <div class="base_child_info_title_cn">新闻列表</div>
        <div class="base_child_info_title_en">NEW LIST</div>
      </div>
      <div class="base_child_info_news">
        <div class="base_child_info_news_bg">
          <el-carousel height="320px" indicator-position="none" ref="newsChildImgCarousel" arrow="never"
            @change="changeChildNewsImgCarousel" class="newsImgCarousel">
            <el-carousel-item v-for="(item, index) in childNewsList" :key="item.newsId + 'newsImg'">
              <img src="../../static/newImg/img_loading.png" class="img_loading" v-show="imgSuffix"/>
              <img :src="item.newsCover+imgSuffix" width="586px" @click="toDetail(item, 22, 'child')" />
            </el-carousel-item>
          </el-carousel>
          <div class="base_child_info_news_bg_bottom">
            <div class="base_child_info_news_bg_bottom_title" v-if="childNewsList.length"
              @click="toDetail(childNewsList[childNewsImageIndex], 22, 'child')">
              {{ childNewsList[childNewsImageIndex].newsTitle }}</div>
            <div>
              <div :class="childNewsImageIndex == index
                ? 'base_child_info_news_bg_bottom_Index active'
                : 'base_child_info_news_bg_bottom_Index'
                " v-for="(i, index) in childNewsList" @click="changeChildNewsImageIndex(index)"
                :key="index + 'newsImg1'">
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="base_child_info_news_left">
          <div class="base_child_info_news_left_title">
            <div :class="childNewsIndex == 33
              ? 'base_child_info_news_left_title_name active'
              : 'base_child_info_news_left_title_name'
              " @mouseenter="getChildList1(33); childNewsIndex = 33">
              最新报道
            </div>
            <div :class="childNewsIndex == 34
              ? 'base_child_info_news_left_title_name active'
              : 'base_child_info_news_left_title_name'
              " @mouseenter="getChildList1(34); childNewsIndex = 34">
              新闻资讯
            </div>
          </div>
          <div class="base_child_info_news_left_list moveTop" @click="toDetail(item, 22, 'child')"
            v-for="item in childNewsList1" :key="'newsList' + item.newsId">
            <div class="base_child_info_news_left_list_title">
              <div class="base_child_info_news_left_list_title_point"></div>{{ item.newsTitle }}
            </div>
            <div class="base_child_info_news_left_list_day">{{ item.publishTime }}</div>
          </div>
        </div>
      </div> -->
      <div class="base_child_info_title" v-show="childProjectList.length">
        <div class="base_child_info_title_cn">项目列表</div>
        <div class="base_child_info_title_en">PROJECT LIST</div>
      </div>
      <div class="base_child_info_project moveTopNormal">
        <div
          class="base_child_info_project_item moveTopNormal"
          v-for="item in childProjectList"
          :key="item.projectId"
          @click="toProject(item)"
        >
          <div class="base_child_info_project_item_img">
            <img :src="item.projectImage" width="100%"/>
          </div>
          <div class="base_child_info_project_item_tips">
            <div
              class="base_child_info_project_item_tips_left"
              style="font-weight: bold"
            >
              {{ item.projectName }}
            </div>
            <div
              class="base_child_info_project_item_tips_left"
              style="font-size: 16px; line-height: 30px; margin-top: 12px"
            >
              项目时间：{{ item.projectDate }}
            </div>
            <div
              class="base_child_info_project_item_tips_left"
              style="font-size: 16px; line-height: 30px"
            >
              所属单位：浙江中峪道地药材有限公司
            </div>
            <div
              class="base_child_info_project_item_tips_left"
              style="font-size: 16px; line-height: 30px"
            >
              产品介绍：<br />
              <div class="base_child_info_project_item_tips_right">
                {{ item.projectDesc }}
              </div>
            </div>
            <div class="base_child_info_project_item_tips_more">
              了解详情<img
                src="../../static/newImg/位图9.png"
                width="16px"
                height="16px"
              />
            </div>
            <div class="base_child_info_project_item_tips_line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Autoplay } from "swiper";
import {
  getCompanyList,
  getCompanyDetail,
  getPojectDetail,
  getSignature,
} from "@/api/index.js";
import axios from "axios";
export default {
  name: "Base",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: "?x-oss-process=image/resize,m_lfit,w_300",
      provinceInfo: {
        city: [],
      },
      cityIndex: 0,
      newsList: [],
      newsList1: [],
      newsList2: [],
      newsList3: [],
      newsList4: [],
      childNewsList: [],
      childNewsList1: [],
      newsImageIndex: 0,
      childNewsImageIndex: 0,
      newsIndex: 33,
      childNewsIndex: 33,
      childProjectList: [
        {
          projectImage: "	https://www.zoneyu.cc/img/img95.13aa8cee.jpg",
          projectName: "平顶山仿野生白术种植基地",
          projectDate: "2023年8月1日起 - 2043年7月31日",
          projectDesc:
            "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月",
          projectId: "0",
        },
        {
          projectImage: "	https://www.zoneyu.cc/img/img95.13aa8cee.jpg",
          projectName: "平顶山仿野生白术种植基地",
          projectDate: "2023年8月1日起 - 2043年7月31日",
          projectDesc:
            "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月",
          projectId: "1",
        },
        {
          projectImage: "	https://www.zoneyu.cc/img/img95.13aa8cee.jpg",
          projectName: "平顶山仿野生白术种植基地",
          projectDate: "2023年8月1日起 - 2043年7月31日",
          projectDesc:
            "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月",
          projectId: "2",
        },
        {
          projectImage: "	https://www.zoneyu.cc/img/img95.13aa8cee.jpg",
          projectName: "平顶山仿野生白术种植基地",
          projectDate: "2023年8月1日起 - 2043年7月31日",
          projectDesc:
            "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月",
          projectId: "3",
        },
      ],
      momentProjectList: [],
      container1: null,
      container2: null,
      showNumType: true,
    };
  },
  filters: {
    fomatterDate: (val) => {
      if (!val) {
        return "";
      }
      if (val.length > 11) {
        val = val.replace(",", "日 至 ");
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val += "日";
      } else {
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace("-", "日");
      }
      return val;
    },
  },
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 4000);
    Swiper.use([Pagination, Autoplay]);
    window.scroll(0, 0);
    if (this.$route.query.companyId) {
      this.companyId = this.$route.query.companyId;
    }
    if (this.$refs.newsImgCarousel) {
      this.slideBanner();
    }
    this.getCompanyDetail();
    // TODO
    this.getMomentProjectList();
    this.changeNewsIndex(33);
    this.changeNewsIndex33(33);
    this.changeNewsIndex34(34);
    this.changeNewsIndex35(35);
    this.changeNewsIndex36(36);
    if (this.$refs.cityCarousel) {
      this.slideBannerCityCarousel();
    }
    window.addEventListener("scroll", this.showNum);
  },
  methods: {
    showNum() {
      if (window.scrollY > 250 && this.showNumType) {
        this.allCountUp();
      }
    },
    allCountUp() {
      new this.$countUp("ZZMJ", 7944).start();
      new this.$countUp("XSDW", 20).start();
      new this.$countUp("GZRY", 3600).start();
      new this.$countUp("QDSJ", 2023).start();
      this.showNumType = false;
    },
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: that.provinceInfo.companyName, // 分享标题
            desc: that.provinceInfo.companyDesc, // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.provinceInfo.companyImage, // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    slideBannerCityCarousel() {
      //选中的轮播图
      var box = document.querySelector(".cityCarousel .el-carousel__container");
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.cityCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.cityCarousel.prev();
          return;
        }
      });
    },
    showDetail() {
      this.$parent.nav_click(2);
    },
    changeCityIndex(index, index1) {
      if (index == 0) {
        this.cityIndex = 0;
        this.container1.slideTo(0);
      } else {
        if (index > index1) {
          this.cityIndex += 1;
          this.container1.slideNext();
        } else {
          this.cityIndex -= 1;
          this.container1.slidePrev();
        }
      }
      this.childNewsIndex = 33;
      this.childNewsImageIndex = 0;
      if (this.provinceInfo.city.length != 0) {
        this.getChildList(33);
        this.getChildList1(33);
        this.getProjectList();
      }
    },
    getCompanyDetail() {
      getCompanyDetail(this.companyId).then((res) => {
        this.provinceInfo = res.data;
        this.getCompanyList();
        this.getWXInfo();
      });
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(
        ".newsImgCarousel .el-carousel__container"
      );
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.newsImgCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.newsImgCarousel.prev();
          return;
        }
      });
    },
    changeNewsImageIndex(index) {
      this.newsImageIndex = index;
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    changeChildNewsImageIndex(index) {
      this.childNewsImageIndex = index;
      this.$refs.newsChildImgCarousel.setActiveItem(index);
    },
    changeNewsImgCarousel(index) {
      this.newsImageIndex = index;
    },
    changeChildNewsImgCarousel(index) {
      this.childNewsImageIndex = index;
    },
    toDetail(item, index, type) {
      if (type == "child") {
        const id3 = this.provinceInfo.city[this.cityIndex].companyId;
        const name3 = this.provinceInfo.city[this.cityIndex].companyName;
        this.$router.push({
          path: "/companyNewsListDetail",
          query: {
            id: item.newsId,
            name1: "首页",
            name2: item.companyName,
            id2: item.companyId,
            name3,
            id3,
          },
        });
      } else {
        this.$router.push({
          path: "/companyNewsListDetail",
          query: {
            id: item.newsId,
            name1: "首页",
            name2: this.provinceInfo.companyName,
            id2: this.provinceInfo.companyId,
          },
        });
      }
    },
    toCompanyDetail(companyId) {
      // 假数据
      // const query = { companyId: companyId, pCompanyName: this.provinceInfo.companyName, pCompanyId: this.provinceInfo.companyId }
      const query = {
        companyId: 19,
        pCompanyName: this.provinceInfo.companyName,
        pCompanyId: 19,
      };
      this.$router.push({
        path: "/baseCompanyDetail",
        query,
      });
    },
    changeCompany(companyId) {
      if (this.provinceInfo.city[this.cityIndex].companyId == companyId) {
        this.toCompanyDetail(companyId);
      }
      this.provinceInfo.city.forEach((val, index) => {
        if (val.companyId == companyId) {
          this.$refs.cityCarousel.setActiveItem(index);
          this.container1.slideTo(index);
        }
      });
    },
    toProject(item) {
      getPojectDetail(item.projectId).then((res) => {
        // const resource = res.data.base.resource.length
        // const productDTOList = res.data.productDTOList.length
        // const landDTOList = res.data.landDTOList.length
        // if (resource && productDTOList && landDTOList) {
        this.$router.push({
          path: "/projectDetail",
          query: { id: item.projectId },
        });
        // }
      });
    },
    changeNewsIndex(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=4&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList = res.data.data.list;
        });
    },
    changeNewsIndex33(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=5&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList1 = res.data.data.list;
        });
    },
    changeNewsIndex34(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=7&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList2 = res.data.data.list;
        });
    },
    changeNewsIndex35(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=5&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList3 = res.data.data.list;
        });
    },
    changeNewsIndex36(index) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=5&websiteCategoryId=${index}&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.newsList4 = res.data.data.list;
        });
    },
    getCompanyList() {
      getCompanyList({ current: 1, size: 4, pid: this.companyId }).then(
        (res) => {
          // TODO假数据
          // this.provinceInfo.city = res.data.list
          this.provinceInfo.city = [
            {
              companyName: "浙江中峪道地药材有限公司",
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。",
              companyId: "1",
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            },
            {
              companyName: "浙江中峪道地药材有限公司",
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。",
              companyId: "2",
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            },
            {
              companyName: "浙江中峪道地药材有限公司",
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。",
              companyId: "3",
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            },
          ];
          if (this.provinceInfo.city.length != 0) {
            this.getChildList(33);
            this.getChildList1(33);
            this.getProjectList();
            this.$nextTick(() => {
              this.container1 = new Swiper(".swiper-container1", {
                loop: true,
                slidesPerView: 4,
                spaceBetween: 30,
                observer: true, //开启动态检查器，监测swiper和slide
                observeParents: true, //监测Swiper 的祖/父元素
                centeredSlides: true,
              });
              setTimeout(() => {
                this.$refs.cityCarousel.setActiveItem(1);
              }, 500);
            });
          }
        }
      );
    },
    getChildList1(websiteCategoryId) {
      const index = this.provinceInfo.city[this.cityIndex].companyId;
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=4&companyId=${index}&websiteCategoryId=${websiteCategoryId}`
        )
        .then(async (res) => {
          this.childNewsList1 = res.data.data.list;
        });
    },
    getChildList(websiteCategoryId) {
      const index = this.provinceInfo.city[this.cityIndex].companyId;
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=4&companyId=${index}&websiteCategoryId=${websiteCategoryId}`
        )
        .then(async (res) => {
          this.childNewsList = res.data.data.list;
        });
    },
    getProjectList() {
      const index = this.provinceInfo.city[this.cityIndex].companyId;
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/project/list?current=1&size=1000&companyId=${index}`
        )
        .then(async (res) => {
          // 假数据
          // this.childProjectList = res.data.data.list;
        });
    },
    // TODO
    getMomentProjectList() {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/project/list?current=1&size=1000&companyId=${this.companyId}`
        )
        .then(async (res) => {
          this.momentProjectList = res.data.data.list;
        });
    },
    toPage(path, query) {
      this.$router.push({ path, query });
    },
  },
  watch: {
    $route() {
      if (this.$route.query.companyId) {
        this.companyId = this.$route.query.companyId;
        this.getCompanyList();
        this.getCompanyDetail();
        this.changeNewsIndex(33);
        this.changeNewsIndex33(33);
        this.changeNewsIndex34(34);
        this.changeNewsIndex35(35);
        this.changeNewsIndex36(36);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime);
    window.removeEventListener("scroll", this.showNum);
  },
};
</script>

<style scoped lang="scss">
.base {
  width: 100%;
  background-color: #fff;

  .base_head {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 560px;

    .base_head_name {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 70px;
      font-weight: bold;
      color: #ffffff;
      letter-spacing: 8px;
      font-family: 楷体;
    }
  }

  .base_head::before {
    content: " ";
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    width: 100%;
    height: 560px;
    top: 0;
    left: 0;
  }

  .base_introduce {
    height: 650px;
    text-align: center;

    .center_title {
      width: 1600px;
      margin: 0 auto;
      margin-top: 54px;
      z-index: 9;
      position: relative;
      text-align: left;

      & > img {
        cursor: pointer;
      }

      .center_home {
        font-weight: 400;
        font-size: 16px;
        color: #1d1b19;
        vertical-align: top;
      }
    }

    .base_introduce_title {
      padding-top: 130px;
      font-weight: bold;
      font-size: 40px;
      color: #000000;
      line-height: 60px;
      letter-spacing: 2px;
      text-align: center;
    }

    .base_introduce_content {
      font-weight: 400;
      font-size: 16px;
      color: #373737;
      line-height: 31px;
      text-align: center;
      margin: 37px 0 128px 0;
    }

    .base_introduce_num {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      font-weight: bold;
      font-size: 60px;
      color: #1e9409;
      line-height: 90px;
      & > div {
        cursor: pointer;
      }

      .base_introduce_num_ZZMJ {
        width: 25%;
        text-align: left;
      }

      .base_introduce_num_GZRY {
        width: 25%;
        text-align: center;
      }

      .base_introduce_num_XSDW {
        width: 25%;
        text-align: center;
      }

      .base_introduce_num_CLSJ {
        width: 25%;
        text-align: right;
      }
    }

    .base_introduce_tips {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      font-size: 21px;
      color: #202020;
      line-height: 33px;
      letter-spacing: 1px;
      text-align: center;
      & > div {
        cursor: pointer;
      }

      .base_introduce_num_ZZMJ {
        width: 25%;
        text-align: left;
      }

      .base_introduce_num_GZRY {
        width: 25%;
        text-align: center;
      }

      .base_introduce_num_XSDW {
        width: 25%;
        text-align: center;
      }

      .base_introduce_num_CLSJ {
        width: 25%;
        text-align: right;
      }
    }
  }

  .base_newsImg {
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/a49edfe4de974fcc9d0b9314aec91b5b.png")
      no-repeat;
    background-size: cover;

    .base_newsImg_title {
      text-align: center;
      margin-top: 140px;

      .base_newsImg_title_bold {
        font-size: 48px;
        color: #000000;
        line-height: 72px;
        letter-spacing: 2px;
      }

      .base_newsImg_title_normal {
        margin-top: 24px;
        font-size: 20px;
        color: #000000;
        line-height: 30px;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 128px;
      }
    }

    .base_news {
      display: flex;
      width: 1600px;
      margin: 50px auto;

      .base_news_bg {
        width: 48%;
        position: relative;
        overflow: hidden;
        transform-style: preserve-3d;

        img {
          transform: translateZ(-1px);
        }

        .base_news_bg_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          color: #fff;
          padding: 0 16px;
          font-size: 14px;
          font-weight: bold;
          width: calc(100% - 32px);
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0000008a;

          .base_news_bg_bottom_title {
            cursor: pointer;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .base_news_bg_bottom_Index {
            float: left;
            color: #354a32;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #ffffff;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
          }

          .base_news_bg_bottom_Index:hover {
            color: #fff;
            background: #354a32;
            border: 1px solid #354a32;
          }

          .active {
            color: #fff;
            background: #354a32;
            border: 1px solid #ffffff;
          }
        }
      }

      .base_news_left {
        margin-left: 40px;
        width: calc(50% - 40px);

        .base_news_left_title {
          display: flex;
          width: 100%;
          border-bottom: 2px solid #efefef;
          margin-bottom: 20px;

          .base_news_left_title_name {
            margin-left: 10px;
            padding: 0 34px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: 0;
            background: #fff;
            color: #000;
            cursor: pointer;
            font-size: 23px;
          }

          .base_news_left_title_name:first-child {
            margin-left: 0;
          }

          .active {
            background: #354a32;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
          }
        }

        .base_news_left_list {
          cursor: pointer;
          width: 100%;
          display: flex;
          line-height: 80px;
          height: 80px;
          justify-content: space-between;

          .base_news_left_list_title {
            width: 85%;
            font-size: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* 当文本溢出时显示省略号 */
            .base_news_left_list_title_point {
              width: 4px;
              height: 4px;
              background: #8c8f92;
              opacity: 0.31;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }
          }

          .base_news_left_list_day {
            font-size: 13px;
            color: #0000008c;
          }
        }
      }
    }

    .base_news1 {
      display: flex;
      width: 1600px;
      margin: 50px auto;
      justify-content: space-between;

      .base_news1_bg {
        width: 48%;
        height: 320px;
        background: linear-gradient(180deg, #ebefe8 0%, #fcfcfc 100%);
        position: relative;
        text-align: center;

        .base_news1_bg_img {
          position: absolute;
          top: 42px;
          left: 80px;
        }

        .base_news1_bg_title {
          margin-top: 28px;
          font-size: 26px;
          font-weight: 600;
          color: #354a32;
          line-height: 37px;
          margin-bottom: 25px;
        }

        .base_news1_bg_list {
          padding: 0 40px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 400;
          color: #211f1f;
          line-height: 40px;
          text-align: left;

          .base_news1_bg_list_point {
            width: 4px;
            height: 4px;
            background: #8c8f92;
            opacity: 0.31;
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .base_banner {
    width: 1600px;
    margin: 0 auto 40px auto;
  }

  .base_child_top {
    background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/7a71a092964e4be9b1c3a50510fa440d.png")
      no-repeat;
    background-size: cover;
    height: 974px;
    text-align: center;
    margin-top: 30px;
    position: relative;

    .base_child_top_title_cn {
      font-size: 31px;
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      padding-top: 150px;
    }

    .base_child_top_title_en {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 27px;
      letter-spacing: 2px;
      margin-top: 22px;
    }

    .base_child_top_tips {
      width: 100%;
      height: 167px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      bottom: 0;
      left: 0;

      .base_child_top_tips_img {
        margin-top: 18px;
        position: relative;
      }

      .base_child_top_tips_line::before {
        content: "";
        background-color: #fff;
        height: 5px;
        width: 120px;
        position: absolute;
        top: 56px;
        left: calc(50% - 60px);
      }

      .base_child_top_tips_line {
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
        margin-top: 14px;
      }

      .base_child_top_tips_name {
        font-size: 24px;
        color: #ffffff;
        line-height: 108px;
        letter-spacing: 2px;
      }
    }

    .base_child_top_img {
      width: 1600px;
      margin: 40px auto;

      .base_child_top_img_out {
        box-shadow: 0px 2px 40px 0px #354a32;

        .base_child_top_img_out_img {
          position: relative;
          text-align: center;
          margin: 0 auto;
          overflow: hidden;
          width: 620px;
          height: 300px;

          div {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 34px;
            font-weight: bold;
            color: #ffffff;
            letter-spacing: 4px;
            text-shadow: 0px 2px 40px #354a32;
            z-index: 9;
          }
        }

        .base_child_top_img_out_img::after {
          content: " ";
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.38),
            rgba(0, 0, 0, 0.88)
          );
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        .base_child_top_img_out_tips {
          padding: 25px;
          background-color: #fff;
          height: 190px;
          width: 570px;
          margin: 0 auto;
          text-align: left;

          .base_child_top_img_out_tips_title {
            font-size: 20px;
            font-weight: 400;
            color: #354a32;
            line-height: 31px;
          }

          .base_child_top_img_out_tips_desc {
            margin: 15px 0;
            font-size: 16px;
            font-weight: 400;
            color: #6b766a;
            line-height: 27px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .base_child_tips {
    width: 100%;
    text-align: center;
    margin-bottom: 88px;
  }

  .base_child_info {
    width: 1600px;
    margin: 60px auto;

    .base_child_info_title {
      text-align: center;
      margin-top: 90px;

      .base_child_info_title_cn {
        font-size: 31px;
        font-weight: bold;
        color: #000000;
        line-height: 47px;
      }

      .base_child_info_title_en {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 400;
        color: #979797;
        line-height: 27px;
        letter-spacing: 2px;
      }
    }

    .base_child_info_news {
      display: flex;
      width: 1600px;
      margin: 50px auto;

      .base_child_info_news_bg {
        width: 586px;
        position: relative;

        .base_child_info_news_bg_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          color: #fff;
          padding: 0 16px;
          font-size: 14px;
          font-weight: bold;
          width: calc(100% - 32px);
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0000008a;

          .base_child_info_news_bg_bottom_title {
            cursor: pointer;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .base_child_info_news_bg_bottom_Index {
            float: left;
            color: #354a32;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #ffffff;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
          }

          .base_child_info_news_bg_bottom_Index:hover {
            color: #fff;
            background: #354a32;
            border: 1px solid #354a32;
          }

          .active {
            color: #fff;
            background: #354a32;
            border: 1px solid #ffffff;
          }
        }
      }

      .base_child_info_news_left {
        margin-left: 40px;
        width: calc(100% - 626px);

        .base_child_info_news_left_title {
          display: flex;
          width: 100%;
          border-bottom: 2px solid #efefef;
          margin-bottom: 20px;

          .base_child_info_news_left_title_name {
            margin-left: 10px;
            padding: 0 34px;
            height: 69px;
            line-height: 69px;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: 0;
            background: #fff;
            color: #000;
            cursor: pointer;
            font-size: 23px;
          }

          .base_child_info_news_left_title_name:first-child {
            margin-left: 0;
          }

          .active {
            background: #354a32;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
          }
        }

        .base_child_info_news_left_list {
          cursor: pointer;
          width: 100%;
          display: flex;
          line-height: 46px;
          height: 46px;
          justify-content: space-between;

          .base_child_info_news_left_list_title {
            width: 85%;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* 当文本溢出时显示省略号 */
            .base_child_info_news_left_list_title_point {
              width: 4px;
              height: 4px;
              background: #8c8f92;
              opacity: 0.31;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }
          }

          .base_child_info_news_left_list_day {
            font-size: 13px;
            color: #0000008c;
          }
        }
      }
    }

    .base_child_info_project {
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .base_child_info_project_item {
        cursor: pointer;
        width: 49%;
        margin-top: 30px;

        .base_child_info_project_item_img {
          width: 100%;
          height: 355px;
          overflow: hidden;

          img {
            display: block;
            transition: all 1s;
          }
        }

        .base_child_info_project_item_tips {
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
          position: relative;

          .base_child_info_project_item_tips_left {
            font-size: 24px;
            font-weight: 400;
            color: #354a32;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            margin-top: 12px;
            font-size: 16px;
            color: #354a32;
            line-height: 27px;
            height: 85px;
            overflow: hidden;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #595959;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }

          .base_child_info_project_item_tips_line {
            width: 0;
            height: 2px;
            background-color: #1e9409;
            transition: all 1s;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      .base_child_info_project_item:hover {
        .base_child_info_project_item_img img {
          transform: scale(1.1);
        }

        .base_child_info_project_item_tips_line {
          width: 100%;
        }
      }

      .base_child_info_project_item_only {
        display: flex;
        margin-top: 20px;
        cursor: pointer;

        .base_child_info_project_item_img {
          width: 590px;
          height: 355px;
          overflow: hidden;

          img {
            display: block;
          }
        }

        .base_child_info_project_item_tips {
          width: calc(100% - 590px - 48px);
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

          .base_child_info_project_item_tips_left {
            font-size: 20px;
            font-weight: 400;
            color: #354a32;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #6b766a;
            line-height: 27px;
            height: 85px;
            overflow: hidden;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #595959;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }
        }
      }
    }
  }
}

.swiper-container1,
.swiper-container2 {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .swiper-slide {
    overflow: hidden;
    cursor: pointer;
  }

  .swiper-slide-active {
    font-weight: bold;
  }
}

.swiper-container2 {
  .swiper-slide-active {
    width: 708px !important;
    z-index: 9;
    transform: scale(1.25);
  }

  .swiper-slide-prev {
    width: 246px !important;
    z-index: 8;
    opacity: 0.56;
    transform: scale(0.8);
  }

  .swiper-slide-next {
    width: 246px !important;
    z-index: 8;
    opacity: 0.56;
    transform: scale(0.8);
  }
}

::v-deep .el-carousel__item {
  opacity: 0.6;
}

::v-deep .el-carousel__item.is-active {
  opacity: 1;
}

::v-deep .el-carousel__mask {
  background-color: transparent;
}
</style>
